<template>
  <!-- Error page-->
  <div>
    <nav-bar />
    <div class="misc-inner p-2 p-sm-3">
      <div class="misc-wrapper container">
        <div class="row text-justify">
          <h2 class="main-title w-100 text-center">Privacy Policy</h2>
          <p>
            This privacy notice explains why DCKAP collects information about
            you, and how that information may be used. As a data controller,
            DCKAP has fair processing responsibilities under the GDPR. This
            means ensuring that your personally identifiable information (PII)
            is handled in ways that are safe, transparent and what you would
            reasonably expect. According to the GDPR, it is important that data
            subjects are made aware of, and understand the way their PII is
            processed and that they have an opportunity to object if they so
            wish, and that they know how to do so. Our site's job alerts
            subscription form requires you to give us your email address. We use
            this information from to send you job alerts. Users may opt-out of
            receiving future mailings by choosing the unsubscribe link in the
            email received by them.
          </p>
          <p><strong>Types of Information we collect</strong></p>
          <p>
            This privacy notice covers the information you share with us and/or
            which may be acquired or produced by DCKAP, its subsidiaries and its
            affiliates directly from you as you use our services, or provided by
            you when you visit our website or our social media pages, as well as
            information that is collected during events organized by DCKAP. For
            the purposes mentioned in this Privacy Notice, we collect the
            following groups of data:
          </p>
          <p class="w-100">
            <strong>Your basic identification and contact details:</strong>
          </p>
          <p>
            This may include for example your name, company, telephone number,
            email address, of and any other information that you provide us.
          </p>
          <p class="w-100"><strong>Your account information:</strong></p>
          <p>
            You provide us information when visiting our corporate website or
            any of the social media pages. This may include for example your
            name, company, official address, telephone number, email address and
            any other information that you provide us.
          </p>
          <p>
            <strong
              >Information about your customer relationship with DCKAP:</strong
            >
          </p>
          <p>
            We collect personal data when you purchase our products and
            services. This data may include for example information about your
            previous purchases of DCKAP products and services, your billing
            information, information about DCKAP events you have participated
            in, and feedback you have given us.
          </p>
          <p><strong>Information on how you use our website:</strong></p>
          <p>
            We use cookies and web beacons to collect data on how you use our
            websites and view our marketing emails. This may include for example
            information on which DCKAP websites you have visited, how long you
            stayed on them, which items you clicked on and your IP-address.
          </p>

          <p>
            <strong>
              For visitors who are interested in learning more about our
              products, support, or services:
            </strong>
          </p>
          <div class="w-100">
            <ul>
              <li>Name</li>
              <li>Company</li>
              <li>Phone</li>
              <li>Email</li>
              <li>Current ERP</li>
              <li>Ecommerce Platform</li>
              <li>Message</li>
            </ul>
          </div>
          <p>
            <strong> For visitors who wish to Contact to DCKAP: </strong>
          </p>
          <ul class="w-100">
            <li>Name</li>
            <li>Phone</li>
            <li>Company</li>
            <li>Email</li>
            <li>Message</li>
          </ul>
          <p>
            <strong>
              For visitors who subscribe to DCKAP blog/ Newsletters/Careers:
            </strong>
          </p>
          <ul class="w-100">
            <li>Email Address</li>
          </ul>
          <p class="w-100">
            <strong>
              For all other visitors who e-mail us directly via an e-mail
              hyperlink:
            </strong>
          </p>
          <ul>
            <li>
              E-mail message, which includes any information contained in the
              message, including information that can be collected by
              technological means.
            </li>
          </ul>
          <p class="w-100">
            We might receive information about you from other sources (for
            example: information received from third parties or website
            visitors) and add it to our database.
          </p>
          <p class="w-100">
            <strong>Cookies and Web Beacons:</strong>
          </p>
          <p class="w-100">
            In particular, we use, as do many other websites, “cookies” or small
            data files stored on your computer's hard drive, as well as “web
            beacons” which are images invisibly embedded on a web page, to
            collect information during visits to the DCKAP website, including
            during chat sessions with DCKAP personnel. The information collected
            includes the visitor's IP address, when the site was visited and
            what pages were visited. In some instances, we also use cookies to
            collect information in connection with future visits from that
            website, to recognize you as a previous visitor, or to track your
            activity on our site. If you follow a link to the site from an
            affiliate or promotion, we store information about your arrival from
            that affiliate or promotion in a session cookie. You do not need to
            have cookies turned on to visit our site. You can refuse cookies by
            turning them off on your browser. But, if you want to access web
            pages that require registration, you need to accept a mandatory
            session cookie, which you can delete after you leave the site. Web
            beacons, which record your IP address and the time of your visit and
            what pages you viewed, and are used by a third party service which
            tracks site usage for us, cannot be turned off.
          </p>
          <p class="w-100">
            <strong>Information from Other Sources:</strong>
          </p>
          <p class="w-100">
            We may obtain additional information about you by using your email
            address, or other information, to directly, or through one or more
            services, search over the internet, or elsewhere. We thereby obtain
            information that appears to be related to such email address or
            other information, such as a name, age, company and position. This
            helps us to update, expand and analyze our records, identify new
            customers, and provide products and services that may be of interest
            to you.
          </p>
          <p class="w-100">
            <strong>Purpose of Processing</strong>
          </p>

          <p class="w-100">
            The personal data you provide will be used for marketing our
            services to you if you have expressed interest in any of our
            products or services or in order to record, support and facilitate
            your participation in the knowledge development activities of your
            choice carried out by the organization.
          </p>
          <p class="w-100">
            How we use information about you depends on why the information was
            originally collected. To give you an idea of how we process personal
            data, we have included some examples below.
          </p>
          <p class="w-100">
            <strong>
              In general, we tend to use your personal data especially for the
              following purposes:
            </strong>
          </p>
          <ul>
            <li>to sell products and services to you;</li>
            <li>to provide customer support for sold goods or services;</li>
            <li>to conduct surveys, marketing events;</li>
            <li>to secure and optimize the website experience;</li>
            <li>to provide marketing communication;</li>
            <li>
              to facilitate participation in knowledge development activities
              like webinars, meetups, and to provide access to resources like
              whitepapers and product literature;
            </li>
          </ul>
          <p class="w-100">
            <strong>Who has Access to the data?</strong>
          </p>
          <p class="w-100">
            We use third parties such as service providers, and partners, to
            collect and process personal data on our behalf. Such service
            providers are only allowed to process your personal data to the
            extent necessary for them to provide the service we have requested
            from them. As a main rule, they are not allowed to use your personal
            data for the benefit of their own business.
          </p>
          <p class="w-100">
            In order to protect your privacy, we require that all our service
            providers keep the personal data we provide them confidential and
            adequately secure. They are also required to abide by data
            processing agreements and applicable data protection legislation.
          </p>
          <p class="w-100">
            Additionally, DCKAP may disclose and transfer your personal data
            with in the DCKAP group of companies.
          </p>
          <p class="w-100">
            As DCKAP is a group of companies with affiliates and service
            providers both within the European Union and outside of it, your
            personal data may be transferred outside the European Union. The
            countries to which your data is transferred may not provide the same
            level of data protection as your home country. Despite this, when
            transferring personal data to foreign countries, we will always look
            after its safety by ensuring that there is a legal basis for the
            transfer and that applicable laws governing data transfers are
            followed.
          </p>
          <p class="w-100">
            For additional information about the third parties who have access
            to your data or data transfer to overseas locations you may mail to
            <a href="mailto:privacy@dckap.com">privacy@dckap.com</a>
          </p>
          <p class="w-100">
            <strong>Third-party links</strong>
          </p>
          <p class="w-100">
            At times, at our discretion, we may include links to other websites
            or offer third-party products or services on our website. These
            third-party sites have separate and independent privacy policies.
            Therefore, we have no responsibility or liability for the content
            and activities of these linked sites. Nonetheless, we seek to
            protect the integrity of our site and welcome any feedback about
            these sites.
          </p>
          <p class="w-100">
            <strong>How long will the data be retained?</strong>
          </p>
          <p class="w-100">
            We store personal data only as long as is necessary to fulfill our
            rights and obligations related to the data. However, as these rights
            and obligations vary from one processing situation to another, we
            provide more information on such storage times in the topic-specific
            privacy notices.
          </p>
          <p class="w-100">
            Once personal data is no longer necessary, we delete it or anonymize
            it as soon as possible
          </p>

          <p class="w-100">
            <strong
              >How do we maintain the confidentiality of your records?</strong
            >
          </p>
          <p class="w-100">
            We are committed to protecting your privacy and will only use
            information collected lawfully in accordance with the GDPR.
          </p>
          <p class="w-100">
            All of our staff, contractors, and board members receive appropriate
            and on-going training to ensure they are aware of their personal
            responsibilities and have contractual obligations to uphold
            confidentiality, enforceable through disciplinary procedures. Only a
            limited number of authorized staff have access to personal
            information both electronic as well as physical where it is
            appropriate to their role and is strictly on a need-to-know basis.
          </p>
          <p class="w-100">
            DCKAP has also implemented a range of internal controls that provide
            structure to how data is stored, managed, transmitted and ultimately
            destroyed.
          </p>
          <p class="w-100">
            <strong>Who are our partners?</strong>
          </p>
          <p class="w-100">
            We may share your information with selected third parties who
            support us with the marketing process, subject to strict agreements
            on how it will be used. The following are examples of the types of
            organizations that we are likely to share information with as part
            of the marketing process: vendors who support in DB Procurement,
            Validation &amp; Cleansing and Marketing Platform providers where
            Campaigns are promoted.
          </p>
          <p class="w-100">
            <strong>Data Subject Rights</strong>
          </p>
          <p class="w-100">
            You may exercise the following rights in relation to your candidate
            data:
          </p>
          <ul>
            <li>
              <strong>The right to be informed:</strong> This privacy notice
              gives you transparency on how DCKAP processes and handles your
              personal data. If you have any questions, please do not hesitate
              to contact us at
              <a href="mailto:privacy@dckap.com">privacy@dckap.com</a>
            </li>
            <li>
              <strong>The right of access:</strong> You are allowed anytime and
              free of charge to access your personal data so that you are aware
              of and can verify the lawfulness of the processing. Information
              will be provided without delay and at the latest within one month
              of receipt.
            </li>
            <li>
              <strong>The right to rectification:</strong> You are entitled to
              have personal data rectified if it is inaccurate or incomplete.
            </li>
            <li>
              <strong>The right to erasure:</strong> You can request the
              deletion or removal of your personal data where there is no
              compelling reason for its continued processing.
            </li>
            <li>
              Rights in relation to automated decision making and profiling.
            </li>
            <li>
              <strong>The right to restrict processing:</strong> You have the
              right to suppress processing of personal data.
            </li>
            <li>
              <strong>The right to data portability:</strong> This allows you to
              obtain and reuse your personal data for your own purposes across
              different services.
            </li>
            <li>
              <strong>The right to objection:</strong> You have the right to
              object to
              <ul>
                <li>
                  Processing based on legitimate interests or the performance of
                  a task in the public interest/exercise of official authority,
                </li>
                <li>Direct marketing and,</li>
                <li>
                  Processing for purposes of scientific/historical research and
                  statistics.
                </li>
              </ul>
            </li>
          </ul>
          <p class="w-100">
            You can exercise those rights at any time and get further
            information regarding the processing of your application data and
            DCKAP's general privacy policy by sending an email to
            <a href="mailto:privacy@dckap.com">privacy@dckap.com</a>. There may
            be a charge for this service. Any changes to this notice will be
            published on our website.
          </p>
          <p class="w-100">
            We will do our best to resolve any issue you might have related to
            our processing of your data. However, if you are not satisfied with
            our answers or actions, you may always turn to your national data
            protection authority.
          </p>
          <p class="w-100">
            <strong>Changes to This Privacy Policy</strong>
          </p>
          <p class="w-100">
            We may update our Privacy Policy once in a while. Thus, we advise
            you to review this page periodically for any updates. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            These changes are effective immediately, after they are posted on
            this page.
          </p>
          <p class="w-100">
            If you have any questions or concerns regarding the privacy policy,
            wish to cease receiving further communications from us, or if you
            any special requests etc., please send an email to&nbsp;<a
              href="mailto:privacy@dckap.com"
              >privacy@dckap.com</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from "bootstrap-vue";
import store from "@/store/index";
import { $themeConfig } from "@themeConfig";

const NavBar = () => import("@/components/NavBar.vue");

export default {
  components: {
    BLink,
    BButton,
    BImg,
    NavBar,
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/error.svg"),
    };
  },
  setup() {
    // App Name
    const { appName, appDarkLogo } = $themeConfig.app;
    return {
      appName,
      appDarkLogo,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
.brand-text {
  line-height: 42px;
  margin-bottom: 0;
}
</style>
